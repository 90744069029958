@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap');

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	font-weight: 400
}

p {
	font-size: 18px;
	line-height: 28px;
	color: #2a2a2a;
	font-weight: 400
}

.appie-sticky.sticky {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1010;
	background: #fff;
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .058823529411764705) !important;
	padding-top: 10px;
	padding-bottom: 10px;
	-webkit-animation: sticky 1.2s;
	animation: sticky 1.2s
}

.appie-header-area {
	padding-top: 20px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 999;
}

.appie-hero-content.appie-hero-content-4 .appie-title {
	font-size: 60px;
	line-height: 70px;
	margin-top: 10px;
	margin-bottom: 22px;
}

.appie-hero-area {
	padding-top: 100px;
	padding-bottom: 100px;
	overflow: hidden;
	position: relative;
	z-index: 10;
}

.appie-hero-content.appie-hero-content-4 a.main-btn {
	border: 2px solid #f04c4d;
	color: #f04c4d;
	background: #fff;
	line-height: 55px;
	padding: 0 40px;
	font-size: 16px;
	margin-top: 40px;
}

.main-btn {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid #2b70fa;
	padding: 0 30px;
	font-size: 15px;
	line-height: 45px;
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	z-index: 5;
	transition: all .4s ease-out 0s;
	background-color: #2b70fa;
}

.secound-btn {
	display: inline-block;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid #2b70fa;
	padding: 0 30px;
	font-size: 15px;
	line-height: 45px;
	border-radius: 6px;
	color: #fff;
	cursor: pointer;
	z-index: 5;
	transition: all .4s ease-out 0s;
	background-color: #2b70fa;
}

a.secound-btn {
	border: 2px solid #f04c4d;
	color: #fff;
	background: #f04c4d;
	line-height: 55px;
	padding: 0 40px;
	font-size: 16px;
	margin-top: 40px;
}

.appie-hero-thumb {
	width: 580px;
}

.appie-title {
	font-size: 44px;
	line-height: 54px;
}

.appie-about-8-box a {
	border: 2px solid #f04c4d;
	color: #f04c4d;
	background: #FFF;
	margin-top: 30px;
}

.nav-item>.active {
	border-bottom: 1px solid #333;
}

.appie-section-title {
	padding-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.appie-about-8-box {
	padding: 50px 50px 50px;
	background: #FFF;
	border-radius: 6px;
	box-shadow: 0 40px 40px 0 rgba(14, 17, 51, .1);
	position: relative;
	overflow: hidden;
	z-index: 10;
}

.appie-about-8-box .thumb {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.appie-about-8-box .title {
	font-size: 30px;
	line-height: 40px;
	font-weight: 500;
	margin-bottom: 14px;
}

.navbar-nav a {
	font-size: 16px;
	line-height: 30px;
	color: #2a2a2a;
	font-weight: 500;
}

.margin-100 {
	margin-top: 100px;
	margin-bottom: 100px;
}

.margin-bottom {
	margin-top: 10px;
	margin-bottom: 100px;
}

.margin-top {
	margin-top: 100px;
	margin-bottom: 10px;
}

.partner {
	background: #dbeafb;
	padding-top: 65px;
	padding-bottom: 65px;
}

.bg-color {
	background: #1f1f1f;
	padding-top: 5px;
	padding-bottom: 5px;
}

.btn-subscribe {
	color: #fff;
	background-color: #f04c4d !important;
	border-color: #f04c4d !important;
	font-weight: 500;
}

a {
	text-decoration: none;
}
.image-box
{
	width: 273px;
	height: auto;
}

.icons {
	width: 32px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 800;
	line-height: 1.3;
}

.img-height {
	height: 350px;
}